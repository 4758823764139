export const listColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '车牌号码',
      width: 100,
      align: 'center',
      dataIndex: 'platNumber'
    },
    {
      title: '保险开始日期',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'insuranceStartDate' }
    },
    {
      title: '保险结束日期',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'insuranceEndDate' }
    },
    {
      title: '责任人',
      width: 80,
      align: 'center',
      dataIndex: 'username',
    },
    {
      title: '车架号',
      width: 200,
      align: 'center',
      dataIndex: 'frameNumber',
    },
    {
      title: '保险金额',
      width: 120,
      align: 'center',
      dataIndex: 'insuranceAmount'
    },
    {
      title: '备注',
      width: 150,
      align: 'center',
      scopedSlots: { customRender: 'expired' }
    },
    {
      title: '操作',
      width: 130,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const detailColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '车牌号码',
      width: 100,
      align: 'center',
      dataIndex: 'platNumber'
    },
    {
      title: '保险开始日期',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'insuranceStartDate' }
    },
    {
      title: '保险结束日期',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'insuranceEndDate' }
    },
    {
      title: '责任人',
      width: 80,
      align: 'center',
      dataIndex: 'username',
    },
    {
      title: '车架号',
      width: 200,
      align: 'center',
      dataIndex: 'frameNumber',
    },
    {
      title: '保险金额',
      width: 100,
      align: 'center',
      dataIndex: 'insuranceAmount'
    },
  ]
}
// 车辆任务状态
export const statusList = function () {
  return [
    { value: 'FREE', label: '空闲' },
    { value: 'GOING', label: '进行中' }
  ]
}
// 车辆用途类型
export const useList = function () {
  return [
    { value: 'OTHER', label: '其他' },
    { value: 'QY', label: '清运' },
    { value: 'XJ', label: '巡检' }
  ]
}

