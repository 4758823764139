<template>
  <a-spin :spinning="spinShow">
    <!--    <a-descriptions :column="2" title="车辆基础信息">-->
    <!--      <a-descriptions-item label="车牌号码">{{ formItem.platNumber }}</a-descriptions-item>-->
    <!--      <a-descriptions-item label="车辆名称">{{ formItem.carName }}</a-descriptions-item>-->
    <!--      <a-descriptions-item label="车架号">{{ formItem.frameNumber || '-' }}</a-descriptions-item>-->
    <!--      <a-descriptions-item label="负责人名称">{{ formItem.username }}</a-descriptions-item>-->
    <!--      <a-descriptions-item label="开始时间">{{ moment(formItem.insuranceStartDate).format('YYYY-MM-DD') }}</a-descriptions-item>-->
    <!--      <a-descriptions-item label="结束时间">{{ moment(formItem.insuranceEndDate).format('YYYY-MM-DD') }}</a-descriptions-item>-->
    <!--      <a-descriptions-item label="保险金额">{{ formItem.insuranceAmount }}</a-descriptions-item>-->
    <!--      <a-descriptions-item label="备注">-->
    <!--       <span style="color: red" v-if="formItem.expired">-->
    <!--            {{ formItem.expiredDays >= 0 ? '距离保险过期还有' + formItem.expiredDays + '天' : '保险已过期' + Math.abs(formItem.expiredDays) + '天' }}</span>-->
    <!--        <span v-else>-</span>-->
    <!--      </a-descriptions-item>-->
    <!--    </a-descriptions>-->
    <a-descriptions :column="1" title="车辆保险历史">
      <a-descriptions-item>
        <a-table
          size="middle"
          :columns="columns"
          :dataSource="formItem.histories"
          :pagination="false"
          :rowKey="(record) => record.id">
          <template slot="_index" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>
          <template slot="insuranceStartDate" slot-scope="text, record">
            {{ moment(record.insuranceStartDate).format('YYYY-MM-DD') }}
          </template>
          <template slot="insuranceEndDate" slot-scope="text, record">
            {{ moment(record.insuranceEndDate).format('YYYY-MM-DD') }}
          </template>
        </a-table>
      </a-descriptions-item>
    </a-descriptions>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { formVO } from './common/FormVO'
  import moment from 'moment'
  import { detailColumns } from './common/common'

  export default {
    name: 'CarInfoDetail',
    components: {},
    props: {
      sup_this: {
        type: Object
      }
    },
    data () {
      return {
        spinShow: false,
        formItem: formVO(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        columns: detailColumns()
      }
    },
    methods: {
      moment,
      loadData (id) {
        this.formItem = formVO()
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInsuranceApi.view,
          params: { id },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            Object.assign(this.formItem, data.body)
            if (data.body.expired) {
              this.sup_this.dayString = data.body.expiredDays >= 0 ? '（距离保险过期还有' + data.body.expiredDays + '天）' :
                '（保险已过期' + Math.abs(data.body.expiredDays) + '天）'
            } else {
              this.sup_this.dayString = ''
            }
            this.sup_this.drawerTitle = data.body.platNumber
            this.spinShow = false
          }
        })
      }
    }
  }
</script>

<style scoped>
  .text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: "Lantinghei SC", "Open Sans", Arial, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", "STHeiti", "WenQuanYi Micro Hei", SimSun, sans-serif;
  }
</style>