<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">车辆保险</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary">新增
      </a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="车辆名称">
              <a-input v-model="searchParams.carName" placeholder="请输入车辆名称" allowClear/>
            </a-form-item>
            <a-form-item label="车牌号码">
              <a-input v-model="searchParams.platNumber" placeholder="请输入车牌号" allowClear/>
            </a-form-item>
            <a-form-item label="负责人">
              <a-input v-model="searchParams.username" placeholder="请输入负责人" allowClear/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
        :scroll="{x:1240}"
        :customRow="handleView">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="insuranceStartDate" slot-scope="text, record">
          {{ moment(record.insuranceStartDate).format('YYYY-MM-DD') }}
        </template>
        <template slot="insuranceEndDate" slot-scope="text, record">
          {{ moment(record.insuranceEndDate).format('YYYY-MM-DD') }}
        </template>
        <template slot="expired" slot-scope="text, record">
          <span style="color: red" v-if="record.expired">
            {{ record.expiredDays >= 0 ? '距离保险过期还有' + record.expiredDays + '天' : '保险已过期' + Math.abs(record.expiredDays) + '天' }}</span>
          <span v-else>-</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="handleEdit(record.id)" @click.stop>编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)" @click.stop>删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      :width="600"
      title="添加车辆保险"
      :visible="addModal"
      :maskClosable="true"
      centered
      @ok="handleAddOk"
      @cancel="closeAddModal">
      <car-info-form ref="addRef" @addSuccess="addSuccess" @addError="addError"></car-info-form>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改车辆保险"
      :width="600"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading">
      <car-info-form ref="editRef" @editSuccess="editSuccess" @editError="editError"></car-info-form>
    </a-modal>
    <a-drawer
      placement="right"
      :closable="true"
      @close="detailClose"
      :mask="false"
      :width="1000"
      :visible="detailDrawer">
      <div slot="title">
        <span>{{drawerTitle}}</span> <span style="font-size: 12px;color: red">{{dayString}}</span>
      </div>
      <car-info-detail ref="viewRef" :sup_this="sup_this"></car-info-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import entityCRUDWithCopy from '../../../common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { listColumns } from './common/common'
  import CarInfoForm from './Form'
  import CarInfoDetail from './Detail'
  import moment from 'moment'

  export default {
    name: 'carInfoList',
    mixins: [entityCRUDWithCopy],
    components: { PageLayout, CarInfoForm, CarInfoDetail },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.carInsuranceApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          carName: '',
          platNumber: '',
          username: ''
        },
        initColumns: listColumns(),
        orgTree: [],
        historyModal: false, //历史任务弹出框
        syncModal: false, //同步信息弹出框
        drawerTitle: '',
        dayString: '',
        sup_this: this
      }
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      moment,
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      rowClick (record) {
        return {
          on: {
            click: () => {
              this.handleView(record.id)
            }
          }
        }
      },
      detailClose () {
        this.detailDrawer = false
      },
      handleHistory (id) {
        this.historyModal = true
        this.$nextTick(() => {
          this.$refs.historyRef.searchRecord(id)
        })
      },
      closeHistoryModal () {
        this.historyModal = false
        this.$nextTick(() => {
          this.$refs.historyRef.resetData()
        })
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.id)
              })
            }
          }
        }
      }
    }
  }
</script>

<style scoped></style>
